<template>
  <Loader />
  <Content />
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* jslint white: true */

import Loader  from './components/Loader.vue';
import Content from './components/Content.vue';

import './assets/js/main.js';
import './assets/js/vendor/jquary.min.js';
import './assets/js/vendor/slick.js';


export default {
  name: 'App',
  components: {
    Loader,
    Content
  }
}
</script>

<style>
@import './assets/css/custom.css';
@import './assets/css/vendor/slick.css';
@import './assets/css/tailwind.css';

#app {
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}



</style>
