<template>
  <div ref="fullscreenElement"
    class="bg-homeBg dark:bg-homeTwoBg-dark min-h-screen bg-no-repeat bg-center bg-cover bg-fixed md:pb-16 w-full">
    <header class="flex justify-between items-center fixed top-0 left-0 w-full lg:static z-[1111111111]">
      <div class="flex justify-between w-full px-4 lg:px-0 bg-[#F3F6F6] lg:bg-transparent">
        <div class="flex justify-center w-full items-center lg:my-8 my-5">
          <!-- website logo -->
          <a class="text-5xl font-semibold" href="#">
            <img :src="amoirie" alt="logo" style="height: 50px; width:60px;" />
          </a>
          <div class="flex text-start">
            <p style="font-size:11px;" class="ml-2 mt-3">MINISTERE DU BUDGET ET DU PORTEFEUILLE DE L’ETAT <br> {{
              entreprise }} <br> DIRECTIONS DES SYSTEMES D'INFORMATION</p>
            <!-- <button
              @click="enterFullScreen"
              type="button"
              class="dark-light-btn lg:hidden w-[44clepx] h-[44px]"
              style="background-color: #555; color: #fff; margin-right: 7px;"
            >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="25"
              height="25"
              fill="currentColor"
            >
              <path
                d="M3 5h18v14H3z"
                fill="none"
              />
              <path
                d="M20 4H4C2.89 4 2 4.89 2 6v12c0 1.11 .89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4V6h16v12zm-6-4H8v-2h6v2z"
              />
            </svg>

            </button>

            <button
              @click="saveContact"
              type="button"
              class="dark-light-btn lg:hidden w-[44px] h-[4px]"
              style="background-color: #f98006; color: #fff"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                width="25"
                height="25"
                fill="currentColor"
              >
                <path
                  d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM272 80v80H144V80h128zm122 352H54a6 6 0 0 1-6-6V86a6 6 0 0 1 6-6h42v104c0 13.255 10.745 24 24 24h176c13.255 0 24-10.745 24-24V83.882l78.243 78.243a6 6 0 0 1 1.757 4.243V426a6 6 0 0 1-6 6zM224 232c-48.523 0-88 39.477-88 88s39.477 88 88 88 88-39.477 88-88-39.477-88-88-88zm0 128c-22.056 0-40-17.944-40-40s17.944-40 40-40 40 17.944 40 40-17.944 40-40 40z"
                />
              </svg>
            </button> -->
            <a class="text-5xl font-semibold mt-3" href="#">
              <img :src="logoEntreprise" alt="logo" style="height: 50px;width:60px;" />
            </a>

            <!-- light and dark mode button -->
            <!-- <button
              id="theme-toggle-mobile"
              type="button"
              class="dark-light-btn lg:hidden w-[44px] h-[4px] ml-2"
              style="background-color: #2b6df8; color: #fff"
            >
              <i id="theme-toggle-dark-icon-mobile" class="fa-solid text-xl fa-moon"></i> 
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="theme-toggle-dark-icon-mobile"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-moon-stars-fill hidden"
                viewBox="0 0 16 16"
                hidden
              >
                <path
                  d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z"
                />
                <path
                  d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z"
                />
              </svg>

              <i id="theme-toggle-light-icon-mobile" class="fa-solid fa-sun text-xl hidden"></i> 
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="theme-toggle-light-icon-mobile"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-brightness-high-fill text-xl hidden"
                viewBox="0 0 16 16"
                hidden
              >
                <path
                  d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"
                />
              </svg>
            </button> -->
            <hr>
          </div>
        </div>
      </div>

      <!-- mobile menu start -->
    </header>
    <div style="background-color : #f26722; height: 30px; width: 60%; margin: 0 auto;"></div>
    <div class="container grid grid-cols-12 md:gap-10 justify-between lg:mt-[220px]">
      <div class="col-span-12 lg:col-span-12">
        <div>
          <div class="lg:rounded-2xl">
            <div class="md:py-12 px-2 sm:px-5 md:px-10 lg:px-14">
              <div class="lg">
                <div class="card">
                  <div class="w-full mb-6 lg:mb-0 mx-auto relative text-center px-6  mt-[180px] md:mt-[220px] lg:mt-0">
                    <div class="image-container" style="position:relative;">
                      <!-- <img
                            :src="carteIvoire"
                            class="w-[700px] absolute left-[50%] transform -translate-x-[50%] h-[700px] drop-shadow-xl mx-auto -mt-[140px]"
                            alt="photo"
                            style="position:absolute;"
                            id="contactImage"
                          /> -->
                      <!-- photo  -->

                      <img :src="photo"
                        class="w-[150px] img absolute left-[50%] transform -translate-x-[50%] h-[150px] drop-shadow-xl mx-auto rounded-[20px] -mt-[150px] mt-5"
                        alt="photo" style="border-radius: 50%; position:absolute;" id="contactImage" />
                      <!-- photo  -->
                    </div>

                    <div class="pt-[100px] pb-8">
                      <h3 class="mt-7 text-[20px] font-semibold" id="nomComplet" style="text-transform: uppercase;">
                        {{ nomComplet }}
                        <!-- carole agoua aka -->
                      </h3>
                      <h5 class="mb-4 text-[#7B7B7B] inline-block px-5 rounded-lg" style="font-size: 17px">
                        <b id="entreprise">
                          {{ emplois }}
                          <!-- Sous Directrice des Infrastructures et Systèmes -->
                        </b><br />
                        <span id="poste">
                          {{ poste }}
                          <!-- Ingenieure Principale de  l’Informatique -->
                        </span>

                      </h5>
                      <div class="flex justify-center space-x-3">
                        <a v-if="facebook" @click="openFacebook(facebook.reseauID._id)" :href="facebook.lien"
                          target="_blank" rel="noopener noreferrer">
                          <span :class="['socialbtn', '[text-' + facebook.reseauID.couleur + ']']">
                            <i class="fab fa-facebook fa-2x" :style="{ 'color': facebook.reseauID.couleur }"></i>
                          </span>
                        </a>
                        <a v-if="twitter" @click="openTwitter(twitter.reseauID._id)" :href="twitter.lien" target="_blank"
                          rel="noopener noreferrer">
                          <span :class="['socialbtn', '[text-' + twitter.reseauID.couleur + ']']">
                            <i class="fab fa-twitter fa-2x" :style="{ 'color': twitter.reseauID.couleur }"></i>
                          </span>
                        </a>
                        <a v-if="instagram" @click="openInstagram(instagram.reseauID._id)" :href="instagram.lien"
                          target="_blank" rel="noopener noreferrer">
                          <span :class="['socialbtn', '[text-' + instagram.reseauID.couleur + ']']">
                            <i class="fab fa-instagram fa-2x" :style="{ 'color': instagram.reseauID.couleur }"></i>
                          </span>
                        </a>
                        <a v-if="linkedin" @click="openLinkedin(linkedin.reseauID._id)" :href="linkedin.lien"
                          target="_blank" rel="noopener noreferrer">
                          <span :class="['socialbtn', '[text-' + linkedin.reseauID.couleur + ']']">
                            <i class="fab fa-linkedin fa-2x" :style="{ 'color': linkedin.reseauID.couleur }"></i>
                          </span>
                        </a>
                        <a v-if="whatsapp" @click="openWhatsapp(whatsapp.reseauID._id)" :href="whatsapp.lien"
                          target="_blank" rel="noopener noreferrer">
                          <span :class="['socialbtn', '[text-' + whatsapp.reseauID.couleur + ']']">
                            <i class="fab fa-whatsapp fa-2x" :style="{ 'color': whatsapp.reseauID.couleur }"></i>
                          </span>
                        </a>
                        <a v-if="youtube" @click="openYoutube(youtube.reseauID._id)" :href="youtube.lien" target="_blank"
                          rel="noopener noreferrer">
                          <span :class="['socialbtn', '[text-' + youtube.reseauID.couleur + ']']">
                            <i class="fab fa-youtube-play fa-2x" :style="{ 'color': youtube.reseauID.couleur }"></i>
                          </span>
                        </a>
                        <a v-if="telegram" @click="openTelegram(telegram.reseauID._id)" :href="telegram.lien"
                          target="_blank" rel="noopener noreferrer">
                          <span :class="['socialbtn', '[text-' + telegram.reseauID.couleur + ']']">
                            <i class="fab fa-telegram fa-2x" :style="{ 'color': telegram.reseauID.couleur }"></i>
                          </span>
                        </a>
                        <a v-if="tiktok" @click="openTiktok(tiktok.reseauID._id)" :href="tiktok.lien" target="_blank"
                          rel="noopener noreferrer">
                          <span :class="['socialbtn', '[text-' + tiktok.reseauID.couleur + ']']">
                            <i class="fab fa-tiktok fa-2x" :style="{ 'color': tiktok.reseauID.couleur }"></i>
                          </span>
                        </a>
                        <a v-if="github" @click="openGithub(github.reseauID._id)" :href="github.lien" target="_blank"
                          rel="noopener noreferrer">
                          <span :class="['socialbtn', '[text-' + github.reseauID.couleur + ']']">
                            <i class="fab fa-github fa-2x" :style="{ 'color': github.reseauID.couleur }"></i>
                          </span>
                        </a>
                      </div>
                      <div class="p-7 rounded-2xl mt-7 bg-[#F3F6F6]">
                        <div class="flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] pb-2.5">
                          <span class="socialbtn bg-white text-[#E93B81] shadow-md orange">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                              class="bi bi-phone" viewBox="0 0 16 17">
                              <path
                                d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                              <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                            </svg>
                          </span>
                          <div class="text-left ml-2.5">
                            <p class="text-xs text-[#44566C]">
                              Téléphone
                            </p>
                            <p class="dark:text-dark">
                              <span id="phoneOne">
                                {{ phone1 }}
                                <!-- +225 0708082162 -->
                              </span>
                              <span v-if="phone2"> / </span>
                              <span id="phoneTwo" v-if="phone2">{{
                                phone2
                              }}</span>
                            </p>
                          </div>
                        </div>
                        <div class="flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] py-2.5">
                          <span class="socialbtn bg-white dark:bg-black text-[#6AB5B9] shadow-md vert">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                              class="bi bi-envelope-at" viewBox="0 0 16 17">
                              <path
                                d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
                              <path
                                d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
                            </svg>
                          </span>
                          <div class="text-left ml-2.5">
                            <p class="text-xs text-[#44566C]">
                              Email
                            </p>
                            <p class="dark:text-dark" id="email">
                              {{ email }}
                              <!-- agoua.carole@douanes.ci -->
                            </p>
                          </div>
                        </div>
                        <div class="flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] py-2.5">
                          <span class="socialbtn bg-white  text-[#FD7590] shadow-md orange">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                              class="bi bi-geo-alt" viewBox="0 0 16 17">
                              <path
                                d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                              <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                            </svg>
                          </span>
                          <div class="text-left ml-2.5">
                            <p class="text-xs text-[#44566C]">
                              Localisation
                            </p>
                            <p class="dark:text-dark" id="localisation">
                              {{ localisation }}
                              <!-- Boulevard du port Treicheville Abidjan, Cote d'ivoire -->
                            </p>
                          </div>
                        </div>
                      </div>
                      <button class="dowanload-btn mb-3" @click="saveContact">
                        Enregistrer le contact
                      </button>
                      <a href="https://smartcard.smartinnovationcenter.com" target="_blank" class="dark:text-white" style="
                          color: #f26722;
                          text-decoration: underline #f26722;
                          font-size: 20px;
                        ">Commander ma carte</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer class="overflow-hidden rounded-b-2xl" style="background: transparent">
              <p class="text-center text-gray-lite dark:text-color-910">
                <a class="hover:text-[#FA5252] duration-300 transition" href="https://smartcard225.com.com"
                  target="_blank" rel="noopener noreferrer">©2023 - BY SMART INNOVATION CENTER</a>
              </p>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import logo from "../assets/images/logo/SMART CARD.png";
import amoirie from '../assets/douaneimages/amoirie.png';
import douanelogo from '../assets/douaneimages/douanelogo.png'
import fonddouane from '../assets/douaneimages/fonddouane.png'
import carteIvoire from '../assets/douaneimages/carteIvoire.png'
import sousdirectriceimg from '../assets/douaneimages/img.jpeg'
export default {
  data: function () {
    return {
      image: logo,
      douanelogo: douanelogo,
      fonddouane: fonddouane,
      carteIvoire: carteIvoire,
      sousdirectriceimg: sousdirectriceimg,
      photo: "",
      amoirie: amoirie,
      logoEntreprise: "",
      nomComplet: "",
      poste: "",
      entreprise: "",
      emplois: "",
      phone1: "",
      phone2: "",
      email: "",
      clientID: "",
      localisation: "",
      siteweb: "",
      facebook: null,
      twitter: null,
      instagram: null,
      linkedin: null,
      whatsapp: null,
      youtube: null,
      telegram: null,
      tiktok: null,
      github: null,
    };
  },
  name: "Content",
  props: [],
  mounted: function () {
    // Récupérer le code dans l'URL
    const code = window.location.pathname.split("/");
    const cliCode = code[code.length - 1];
    //${cliCode}
    axios
      .get(
        `https://smartcard225.com:7001/api/v1/clients/getByCode/${cliCode}`
      )
      .then((response) => {
        var client = response.data.client;
        console.log(client)
        this.clientID = client.id
        this.nomComplet = client.nomComplet;
        this.phone1 = client.phoneOne;
        this.phone2 = client.phoneTwo;
        this.email = client.email;

        axios
          .get(
            `https://smartcard225.com:7001/api/v1/images/photoClient/${client.id}`,
            {
              responseType: "arraybuffer",
            }
          )
          .then((response) => {
            const blob = new Blob([response.data], {
              type: response.headers["content-type"],
            });
            this.photo = URL.createObjectURL(blob);
          })
          .catch((error) => {
            console.error(error);
          });

        axios
          .get(
            `https://smartcard225.com:7001/api/v1/images/logoEntreprise/${client.entrepriseID.id}`,
            {
              responseType: "arraybuffer",
            }
          )
          .then((response) => {
            const blob = new Blob([response.data], {
              type: response.headers["content-type"],
            });
            this.logoEntreprise = URL.createObjectURL(blob);
          })
          .catch((error) => {
            console.error(error);
          });

        this.poste = client.poste;
        this.emplois = client.emploi
        this.entreprise = client.entrepriseID.denomination;
        this.localisation = client.entrepriseID.localisation;
        this.siteweb = client.entrepriseID.siteweb;

        axios
          .get(
            `https://smartcard225.com:7001/api/v1/socialisations/findSocialisationByClientID/${client.id}`
          )
          .then((response) => {
            var reseaux = response.data.socialisation;

            reseaux.forEach((element) => {
              if (
                element.reseauID.libelle == "Facebook" &&
                element.visible === true
              ) {
                this.facebook = element;
              }
              if (
                element.reseauID.libelle == "Twitter" &&
                element.visible === true
              ) {
                this.twitter = element;
              }
              if (
                element.reseauID.libelle == "Instagram" &&
                element.visible === true
              ) {
                this.instagram = element;
              }
              if (
                element.reseauID.libelle == "Linkedin" &&
                element.visible === true
              ) {
                this.linkedin = element;
              }
              if (
                element.reseauID.libelle == "Whatsapp" &&
                element.visible === true
              ) {
                this.whatsapp = element;
              }
              if (
                element.reseauID.libelle == "Youtube" &&
                element.visible === true
              ) {
                this.youtube = element;
              }
              if (
                element.reseauID.libelle == "Telegram" &&
                element.visible === true
              ) {
                this.telegram = element;
              }
              if (
                element.reseauID.libelle == "Tiktok" &&
                element.visible === true
              ) {
                this.tiktok = element;
              }
              if (
                element.reseauID.libelle == "Github" &&
                element.visible === true
              ) {
                this.github = element;
              }
            });
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch(function (error) {
        //console.log(error);
      });
  },
  methods: {

    enterFullScreen() {
      const element = document.documentElement;

      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    },

    // saveContact: function () {
    //   //incrementer la sauvegarde de contacts

    //   axios
    //     .get(
    //       `https://smartcard225.com:7001/api/v1/clients/saveContact/${this.clientID}`
    //     )
    //     .then((response) => {
    //       console.log(response);
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });

    //   // sauvegarder dans repertoir

    //   var nomComplet = $("#nomComplet").text();
    //   var phone1 = $("#phoneOne").text();
    //   var phone2 = $("#phoneTwo").text();
    //   var email = $("#email").text();
    //   var website = this.siteweb;
    //   var localisation = $("#localisation").text();
    //   var entreprise = $("#entreprise").text();
    //   var poste = $("#poste").text();

    //   var image = $("#contactImage").attr("src");
    //   image = image.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");

    //   var vCard = "BEGIN:VCARD\n";
    //   vCard += "VERSION:3.0\n";
    //   vCard += "FN:" + nomComplet + "\n";
    //   vCard += "TEL;TYPE=CELL:" + phone1 + "\n";
    //   vCard += "TEL;TYPE=CELL:" + phone2 + "\n";
    //   vCard += "EMAIL;TYPE=INTERNET:" + email + "\n";
    //   vCard += "URL;TYPE=WORK:" + website + "\n";
    //   vCard += "ADR;TYPE=WORK:;;" + localisation + ";;\n";
    //   vCard += "ORG:" + entreprise + "\n";
    //   vCard += "TITLE:" + poste + "\n";
    //   vCard += "PHOTO;TYPE=JPEG;ENCODING=BASE64:" + image + "\n";
    //   vCard += "END:VCARD";

    //   var dataURL =
    //     "data:text/vcard;charset=utf-8," + encodeURIComponent(vCard);
    //   var downloadLink = $("<a></a>");
    //   var filename = nomComplet
    //     .normalize("NFD")
    //     .replace(/[\u0300-\u036f]/g, "")
    //     .replace(/[^\w\s]/gi, "")
    //     .replace(/\s+/g, "")
    //     .toLowerCase();
    //   downloadLink.attr("href", dataURL);
    //   downloadLink.attr("download", filename + ".vcf");
    //   downloadLink.get(0).click();
    // },

    saveContact: function () {
    // Incrémenter la sauvegarde de contacts
    axios
      .get(`https://smartcard225.com:7001/api/v1/clients/saveContact/${this.clientID}`)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });

    // Récupérer les données du DOM avec vérification
    var nomCompletElement = document.getElementById("nomComplet");
    var phone1Element = document.getElementById("phoneOne");
    var phone2Element = document.getElementById("phoneTwo");
    var emailElement = document.getElementById("email");
    var localisationElement = document.getElementById("localisation");
    var entrepriseElement = document.getElementById("entreprise");
    var posteElement = document.getElementById("poste");
    var imageElement = document.getElementById("contactImage");

    var nomComplet = nomCompletElement ? nomCompletElement.innerText.trim() : "";
    var phone1 = phone1Element ? phone1Element.innerText.trim() : "";
    var phone2 = phone2Element ? phone2Element.innerText.trim() : "";
    var email = emailElement ? emailElement.innerText.trim() : "";
    var website = this.siteweb ? this.siteweb.trim() : "";
    var localisation = localisationElement ? localisationElement.innerText.trim() : "";
    var entreprise = entrepriseElement ? entrepriseElement.innerText.trim() : "";
    var poste = posteElement ? posteElement.innerText.trim() : "";
    var image = imageElement ? imageElement.getAttribute("src") : "";

    // Nettoyer l'image si elle est en base64
    if (image.startsWith("data:image/")) {
      image = image.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
    }

    // Diviser le nom complet en parties
    var nameParts = nomComplet.split(" ");
    var lastName = nameParts.pop();
    var firstName = nameParts.join(" ");

    // Créer le contenu vCard
  var vCard = "BEGIN:VCARD\n";
  vCard += "VERSION:3.0\n";
  vCard += "REV:" + new Date().toISOString() + "\n"; // Ajoute la date et l'heure actuelle au format ISO 8601
  vCard += "N;CHARSET=utf-8:" + lastName + ";" + firstName + ";;;\n";
  vCard += "FN;CHARSET=utf-8:" + nomComplet + "\n";
  if (phone1) vCard += "TEL;TYPE=CELL,VOICE:" + phone1 + "\n";
  if (phone2) vCard += "TEL;TYPE=CELL,VOICE:" + phone2 + "\n";
  if (email) vCard += "EMAIL;TYPE=INTERNET,HOME:" + email + "\n";
  if (website) vCard += "URL:" + website + "\n";
  if (localisation) vCard += "ADR;TYPE=WORK:;;" + localisation + ";;;;\n";
  if (entreprise) vCard += "ORG;CHARSET=utf-8:" + entreprise + "\n";
  if (poste) vCard += "TITLE;CHARSET=utf-8:" + poste + "\n";
  vCard += "END:VCARD"; 

    // Créer un blob et une URL de téléchargement
    var blob = new Blob([vCard], { type: "text/vcard;charset=utf-8" });
    var url = URL.createObjectURL(blob);

    // Créer et déclencher le téléchargement
    var downloadLink = document.createElement("a");
    var filename = nomComplet
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^\w\s]/gi, "")
      .replace(/\s+/g, "")
      .toLowerCase();
    downloadLink.href = url;
    downloadLink.download = filename + ".vcf";
    document.body.appendChild(downloadLink); // Append to body to support older browsers
    downloadLink.click();
    document.body.removeChild(downloadLink); // Clean up
    URL.revokeObjectURL(url); // Clean up
  },

    async openFacebook(id) {
      try {
        const data = {
          reseauID: id
        };
        axios
          .post(
            `https://smartcard225.com:7001/api/v1/clients/visite/${this.clientID}`,
            data
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        this.logOut();
        console.log(error);
      }
    },

    async openLinkedin(id) {
      try {
        const data = {
          reseauID: id
        };
        axios
          .post(
            `https://smartcard225.com:7001/api/v1/clients/visite/${this.clientID}`,
            data
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        this.logOut();
        console.log(error);
      }
    },

    async openInstagram(id) {
      try {
        const data = {
          reseauID: id
        };
        axios
          .post(
            `https://smartcard225.com:7001/api/v1/clients/visite/${this.clientID}`,
            data
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        this.logOut();
        console.log(error);
      }
    },

    async openTwitter(id) {
      try {
        const data = {
          reseauID: id
        };
        axios
          .post(
            `https://smartcard225.com:7001/api/v1/clients/visite/${this.clientID}`,
            data
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        this.logOut();
        console.log(error);
      }
    },

    async openWhatsapp(id) {
      try {
        const data = {
          reseauID: id
        };
        axios
          .post(
            `https://smartcard225.com:7001/api/v1/clients/visite/${this.clientID}`,
            data
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        this.logOut();
        console.log(error);
      }
    },

    async openYoutube(id) {
      try {
        const data = {
          reseauID: id
        };
        axios
          .post(
            `https://smartcard225.com:7001/api/v1/clients/visite/${this.clientID}`,
            data
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        this.logOut();
        console.log(error);
      }
    },

    async openTelegram(id) {
      try {
        const data = {
          reseauID: id
        };
        axios
          .post(
            `https://smartcard225.com:7001/api/v1/clients/visite/${this.clientID}`,
            data
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        this.logOut();
        console.log(error);
      }
    },

    async openTiktok(id) {
      try {
        const data = {
          reseauID: id
        };
        axios
          .post(
            `https://smartcard225.com:7001/api/v1/clients/visite/${this.clientID}`,
            data
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        this.logOut();
        console.log(error);
      }
    },

    async openGithub(id) {
      try {
        const data = {
          reseauID: id
        };
        axios
          .post(
            `https://smartcard225.com:7001/api/v1/clients/visite/${this.clientID}`,
            data
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        this.logOut();
        console.log(error);
      }
    },
  },
};
</script>

<style>
.bg-homeBg {
  background-image: url('../assets/douaneimages/fonddouane.png');

}

/* .image{
    background-image: url('../assets/douaneimages/carteIvoire.png');
  } */

.img {
  width: 200px !important;
  height: 200px !important;
}
</style>
